var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.error,
      expression: "!!error"
    }],
    staticClass: "invalid-feedback font-w600",
    attrs: {
      "id": _vm.id
    }
  }, [_vm._v(_vm._s(_vm.niceError))]);
}
var staticRenderFns = []

export { render, staticRenderFns }