<template>
  <div class="d-flex align-items-center" :class="{ 'TabTitle-border': border }">
    <div v-if="$slots.actions && actionPosition === 'left'" class="border-right mr-3 pr-1">
      <slot name="actions">
        <a class="btn btn-link" @click="$router.go(-1)"><i class="fa fa-arrow-left mr-1"></i></a>
      </slot>
    </div>
    <div class="d-flex align-items-center flex-grow-1">
      <div><i v-if="icon" class="fa-duotone duotone-etainabl fa-2x fa-fw mr-3" :class="icon"></i></div>
      <div>
        <h2 class="mb-0"><slot>Page Title</slot></h2>
        <p class="mb-0 text-muted"><slot name="sub-title"></slot></p>
      </div>
    </div>
    <div v-if="$slots.actions && actionPosition === 'right'">
      <slot name="actions">
        <a class="btn btn-link" @click="$router.go(-1)"><i class="fa fa-arrow-left mr-1"></i></a>
      </slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabTitle',
  props: {
    icon: {
      type: String,
      required: false,
      default: null
    },
    actionPosition: {
      type: String,
      required: false,
      default: 'left'
    },
    border: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
<style scoped>
.TabTitle-border {
  border-bottom: 3px solid #65c198;
  padding-bottom: 12px;
}
</style>
