<template>
  <div class="form-group" v-bind="$attrs">
    <label v-if="label" :for="id" :class="{ 'mb-0': descriptionPosition === 'top' }"><i v-if="icon" :class="`fa ${icon} mr-2`"></i>{{ label }}</label>
    <div v-if="description && descriptionPosition === 'top'" class="text-gray-dark font-w600 font-size-sm mb-2">{{ description }}</div>
    <FormItem v-bind="$props" :value="value" @input="onInput" @error="onInputError" @itemAdded="onItemAdded" @itemRemoved="onItemRemoved" />
    <div v-if="description && descriptionPosition === 'bottom'" class="text-gray-dark font-w600 font-size-sm mt-1">{{ description }}</div>
    <FormError :id="id" :error="errorMessage" />
  </div>
</template>
<script>
import FormItem from '@/components/FormItem';
import FormError from '@/components/FormError';

export default {
  name: 'FormGroup',
  components: {
    FormItem,
    FormError
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: null,
      default: ''
    },
    options: {
      type: [Array, Object],
      default: () => []
    },
    error: {
      type: String,
      default: ''
    },
    dateFormat: {
      type: String,
      default: null
    },
    dateMinViewMode: {
      type: String,
      default: 'days'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isAlt: {
      type: Boolean,
      default: false
    },
    alt: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    descriptionPosition: {
      type: String,
      default: 'bottom'
    },
    search: {
      type: Boolean,
      default: false
    },
    overflow: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    sliderType: {
      type: String,
      default: 'single'
    },
    hideNoOptionsLabel: {
      type: Boolean,
      default: false
    },
    uniqueOnly: {
      type: Boolean,
      default: false
    },
    dateRange: {
      type: Object,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      childError: ''
    };
  },
  computed: {
    niceError() {
      if (this.error === `Path \`${this.id}\` is required.`) {
        return 'This field is required.';
      } else {
        return this.error;
      }
    },
    errorMessage() {
      return this.error || this.childError;
    }
  },
  methods: {
    onInputError(val) {
      this.childError = val;
    },
    onInput(val) {
      this.$emit('input', val);
    },
    onItemAdded(val) {
      this.$emit('itemAdded', val);
    },
    onItemRemoved(val) {
      this.$emit('itemRemoved', val);
    }
  }
};
</script>
