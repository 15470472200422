<template>
  <div v-show="!!error" :id="id" class="invalid-feedback font-w600">{{ niceError }}</div>
</template>
<script>
export default {
  name: 'FormError',
  props: {
    id: {
      type: String,
      required: true
    },
    error: {
      type: String,
      default: ''
    }
  },
  computed: {
    niceError() {
      if (this.error === `Path \`${this.id}\` is required.`) {
        return this.$t('PATH_REQUIRED');
      } else {
        return this.error;
      }
    }
  }
};
</script>
