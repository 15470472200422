<template>
  <div>
    <TabTitle class="mb-4" icon="fa-plus" action-position="right">
      <template>{{ $route.params.permissionId ? 'Edit' : 'Create' }} Permission</template>
      <template #sub-title>{{ $route.params.permissionId ? 'Edit' : 'Create' }} a new permission to this company.</template>
      <template #actions>
        <router-link class="btn btn-default" :to="{ path: `/company/permissions` }"
          >Back to permissions <i class="fa fa-arrow-right ml-1"></i
        ></router-link>
      </template>
    </TabTitle>
    <Spinner v-if="loading" />
    <div v-else class="block-content">
      <div v-if="error" class="alert alert-danger">{{ error }}</div>

      <PermissionForm v-model="form" :error="validationErrors" />

      <div class="text-right pb-4">
        <button type="submit" class="btn btn-primary" :disabled="loading" @click="onClickSubmit">
          <span v-if="loading"><i class="fa fa-spin fa-spinner mr-1"></i> {{ $route.params.permissionId ? 'Updating' : 'Creating' }}...</span>
          <span v-else><i class="fa fa-check-circle mr-1"></i> Submit</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PermissionForm from '@/components/forms/PermissionForm';
import TabTitle from '@/components/base/TabTitle';
import Spinner from '@/components/SpinnerLogo';

export default {
  name: 'CompanyPermissionsManage',
  components: {
    PermissionForm,
    Spinner,
    TabTitle
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      permission: 'permission/permission',
      loadingAction: 'permission/loadingAction',
      errorAction: 'permission/errorAction',
      validationErrors: 'permission/validationErrors'
    }),
    loading() {
      return this.loadingAction.create || this.loadingAction.update || this.loadingAction.get;
    },
    error() {
      return this.errorAction.create || this.errorAction.update;
    }
  },
  async mounted() {
    if (this.$route.params.permissionId) {
      await this.getPermission({ id: this.$route.params.permissionId });

      this.form = { ...this.permission };
    } else {
      this.form = {};
    }
  },
  methods: {
    ...mapActions({
      getPermission: 'permission/get',
      createPermission: 'permission/create',
      updatePermission: 'permission/update'
    }),
    onClickSubmit() {
      if (this.$route.params.permissionId) {
        this.onUpdatePermission();
      } else {
        this.onCreatePermission();
      }
    },
    async onCreatePermission() {
      const response = await this.createPermission({ data: this.form });

      if (response._id) {
        this.$toasted.success('Permission created successfully!', { position: 'bottom-right', duration: 3000 });
        this.$router.push({ name: 'company-permissions-list', params: { id: this.$auth.companyId } });
      } else {
        this.$toasted.error('Permission was not created successfully.', { position: 'bottom-right', duration: 3000 });
      }
    },
    async onUpdatePermission() {
      const response = await this.updatePermission({ id: this.$route.params.permissionId, data: this.form });

      if (response._id) {
        this.$toasted.success('Permission updated successfully!', { position: 'bottom-right', duration: 3000 });
        this.$router.push({ name: 'company-permissions-list', params: { id: this.$auth.companyId } });
      } else {
        this.$toasted.error('Permission was not updated successfully.', { position: 'bottom-right', duration: 3000 });
      }
    }
  }
};
</script>
